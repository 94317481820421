@import "src/assets/scss/elements";

html, body, input, button {
  font-family: "Roboto", sans-serif;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  height: 100vh;
  background-color: #f2f2f8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.popupClassName {
  background-color: white;
  border-radius: 4px;
  border: 1px rgba(100, 100, 111, 0.2);
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}

.ant-select-item-group {
  font-size: 16px !important;
  position: relative !important;
  padding-left: 24px !important;
  font-weight: 700 !important;

  &:before {
    content: "\2630" !important;
    position: absolute;
    left: 2px;
    top: 50%;
    translate: 0 -60%;
    font-size: 20px;
    line-height: 1;
  }
}

.ant-select-dropdown .ant-select-item-option-grouped {
  padding-left: 12px !important;
}
