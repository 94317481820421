
.word-break {
  word-break: break-word;
}

.bg-black {
  background-color: black;

  &:hover {
    background-color: black !important;
    opacity: 0.7;
  }
}

.bg-white {
  background-color: white;
  border-radius: 8px;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.fw-m {
  font-weight: 500;
}

.font-500 {
  font-weight: 500;
}

.font-bold {
  font-weight: bold;
}

.router-link-active {
  color: #ff7502 !important;
  border-bottom: 1px solid #ff7502;
}

.color-black {
  color: black;

  &:hover {
    color: #ff7502 !important;
    opacity: 0.7;
  }
}

.flex {
  display: flex;
}

.no-wrap {
  flex-wrap: nowrap;
  white-space: nowrap;
}

.text-wrap {
  text-wrap: wrap;
}

.direction-column {
  flex-direction: column;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.items-center {
  align-items: center;
}

.flex-col {
  flex-direction: column;
}

.m-last-auto{
  >:last-child {
    margin-right: auto;
  }
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.items-end {
  justify-content: flex-end;
}

.flex-end {
  justify-content: flex-end;
}

.gap-2 {
  gap: 8px;
}

.overflowX-auto {
  overflow-x: auto;
}

.gap-4 {
  gap: 16px;
}

.p-0 {
  padding: 0;
}

.pointer {
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.invisible-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.mt-1 {
  margin-top: 4px;
}

.mt-2 {
  margin-top: 8px;
}

.mt-4 {
  margin-top: 16px;
}

.mt-8 {
  margin-top: 32px;
}

.mb-1 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 8px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-8 {
  margin-bottom: 32px;
}

.mr-1 {
  margin-right: 4px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-4 {
  margin-right: 16px;
}

.mr-6 {
  margin-right: 24px;
}

.mr-8 {
  margin-right: 32px;
}

.ml-1 {
  margin-left: 4px;
}

.ml-2 {
  margin-left: 8px;
}

.ml-4 {
  margin-left: 16px;
}

.ml-5 {
  margin-left: 20px;
}

.mr-5 {
  margin-right: 20px;
}

.ml-6 {
  margin-left: 24px;
}

.ml-8 {
  margin-left: 32px;
}

.ml-auto {
  margin-left: auto;
}

.pt-0 {
  padding: 0;
}

.p-1 {
  padding: 4px;
}

.p-2 {
  padding: 8px;
}

.p-4 {
  padding: 16px;
}

.p-6 {
  padding: 22px;
}

.pt-1 {
  padding-top: 4px;
}

.pt-2 {
  padding-top: 8px;
}

.pt-4 {
  padding-top: 16px;
}

.pb-1 {
  padding-bottom: 4px;
}

.pb-2 {
  padding-bottom: 8px;
}

.pb-4 {
  padding-bottom: 16px;
}

.pb-6 {
  padding-bottom: 24px;
}

.pl-1 {
  padding-left: 4px;
}

.pl-2 {
  padding-left: 8px;
}

.pl-4 {
  padding-left: 16px;
}

.pl-6 {
  padding-left: 24px;
}

.pl-8 {
  padding-left: 32px;
}

.pr-1 {
  padding-right: 4px;
}

.pr-2 {
  padding-right: 8px;
}

.pr-4 {
  padding-right: 16px;
}

.pr-6 {
  padding-right: 24px;
}

.pr-8 {
  padding-right: 32px;
}

.block {
  display: block;
}

.line-height-m {
  line-height: 24px;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.w-full {
  width: 100%;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 10px;
}

.top-10 {
  top: calc(100% + 5px);
}

.box-shadow {
  box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}

.border {
  border: 1px rgba(100, 100, 111, 0.2);
}

.z-index {
  z-index: 1;
}

.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%);
}

.collapse {
  display: grid !important;
  grid-template-rows: 0fr;
  transition: all .3s;
  overflow: hidden;

  &--open {
    grid-template-rows: 1fr;
  }

  &-in {
    width: 100%;
    overflow: hidden;
  }
}

.overflow-ellipsis {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-center {
  text-align: center;
}

.fixed-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}
